.block.printable-brochure {
  padding: 64px 0;
  background-color: @primary-bg;
  background-repeat: repeat;
  position: relative;
  .bg-colors {
    background: linear-gradient(to bottom, transparent 900px, @page-bg 0);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .print-container {
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
    position: relative;
  }

  article {
    width: 100%;
  }

  .print-btn {
    margin: 20px 0 50px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .printable-area {
    padding: 32px;
    background-color: @page-bg;
  }

  .logo-wrapper {
    max-width: 300px;
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
    img[src^="https://placehold"] {
      display: none;
    }
  }

  .detail-block {
    padding: 20px 0;
    border-bottom: 1px solid #cccccc;
    .text {
      p, ul, ol {
        margin-bottom: 10px;
        font-size: 1.733rem;
        color: @button-bg;
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    th, td {
      border: none;
    }

    tbody {
      border: 1px solid #cccccc;
    }

    th {
      background-color: @primary-bg;
      color: @text-alt;
      text-align: left;
      padding: 18px 5px;
      font-size: 1.2rem;
      word-wrap: break-word;
    }

    tr {
      &:nth-child(even) {
        background-color: @button-bg-hover;
        color: @text-alt;
      }
      &:nth-child(odd) {
        background-color: @page-bg;
        color: @button-bg;
      }
      td {
        text-align: left;
        padding: 8px 5px;
        font-size: 1.2rem;
        word-wrap: break-word;
      }
    }
    caption {
      caption-side: bottom;
      margin: 20px 0 10px;
      font-size: 1.2rem;
      color: @text;
    }
  }

  .address, .phone-wrapper, .email-wrapper {
    margin-bottom: 10px;
    * {
      font-size: 1.733rem;
      color: @button-bg;
    }
  }

  .hours {
    padding-top: 10px;
    color: @button-bg;
    .text {
      * {
        color: @button-bg;
      }
    }
  }

  .icons {
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 10px;
  }

  .vp(470px, {
    table {
      th {
        font-size: 1.6rem;
      }
      td {
        font-size: 1.4rem;
      }
    }
  });

  .vp(600px, {
    table {
      th {
        font-size: 1.8rem;
      }
      tr {
        td {
          font-size: 1.6rem;
        }
      }
    }
  });

  .tablet({
    padding: 64px 32px;
    table {
      th {
        font-size: 2.2rem;
        padding: 18px 15px;
      }
      tr {
        td {
          padding: 8px 15px;
          font-size: 1.73rem;
        }
      }
    }
  });
}

#rs-builder {
  img {
    display: block !important;
  }
}

